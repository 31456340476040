<template>
  <van-popup v-model:show="isShow" position="bottom" :style="{
    height: '80vh',
    borderRadius: '16px 16px 0 0',
    overflow: 'hidden'
  }" @closed="handleClose">
    <div class="content-box">
      <div class="top">
        <div>{{ $t("售後申請") }}</div>
        <img @click="handleClose" src="@/assets/icons/ic_Close@2x.png" alt="" />
      </div>
      <div class="main">
        <div class="title">服務類型</div>
        <div class="service-type" v-if="status!=2&&status!=6">
          <div class="left">
            <div class="title">全部退货退款</div>
            <div class="text">退貨商品無拆封、無破損，不影響二次銷售</div>
          </div>
          <div class="right">
            <img src="@/assets/icons/arrow-rightactive.png" alt="" />
          </div>
        </div>
        <div class="service-type" v-if="status!=2&&status!=6">
          <div class="left">
            <div class="title">部分退货退款</div>
            <div class="text">退貨商品無拆封、無破損，不影響二次銷售</div>
          </div>
          <div class="right">
            <img src="@/assets/icons/arrow-rightactive.png" alt="" />
          </div>
        </div>
        <div class="service-type" @click="handleRefund">
          <div class="left">
            <div class="title">僅退款</div>
            <div class="text">未收到貨或其他特殊情況</div>
          </div>
          <div class="right">
            <img src="@/assets/icons/arrow-rightactive.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script>
export default {
  name: "afterSalesPopup",
  data () {
    return {
      isShow: false,
      orderNo: null,
      status:null
    }
  },
  methods: {
    handleRefund () {
      this.$router.push(`/user/selectGoods/${this.orderNo}`)
    },
    handleOpen (data) {
      this.orderNo = data.orderNo
      this.status = data.status
      this.isShow = true
    },
    handleClose () {
      this.orderNo = null
      this.status = null
      this.isShow = false
    }
  }
}
</script>
<style scoped lang="scss">
.content-box {
  padding: 20px 16px;

  .top {
    position: relative;
    text-align: center;
    font-weight: 500;

    div {
      text-align: center;
    }

    img {
      width: 24px;
      position: absolute;
      right: 0px;
      top: -3px;
    }
  }

  .main {
    .title {
      font-weight: 400;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 24px;
      margin-bottom: 12px;
    }

    .service-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 16px;
      background-color: #F8F8F8;
      margin-bottom: 12px;
      border-radius: 8px 8px 8px 8px;

      &:last-child {
        margin: 0;
      }

      .left {
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #1A1A1A;
          line-height: 24px;
          margin-bottom: 10px;
        }

        .text {
          font-weight: 400;
          font-size: 15px;
          color: #666666;
          line-height: 24px;
        }
      }

      .right {
        width: 16px;
        height: 16px;
        font-size: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>